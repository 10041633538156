import { useEffect, useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { getLead, getOutcomeOptions } from "./api";
import { PageView, Status, Tabs } from "../../GlobalComponents";
import styled from "@emotion/styled";
import InvoicesList from "./InvoicesList";
import Button from '@mui/material/Button';
import { Tooltip } from "@mui/material";
import OutcomeModal from "./OutcomeModal";
import { InvoiceModal } from "../../GlobalComponents";
import LeadInfoTable from "./LeadInfoTable";
import PaymentTransactions from "./PaymentTransactions";
import Notes from "./Notes";
import { useParams, Link } from "react-router-dom";
import { parseNotes } from "../../util";
import { useMsal } from "@azure/msal-react";
import DevToolsModal from "./DevToolsModal";
import LoadingModal from "./LoadingModal";
import CollectionsHistory from "./CollectionsHistory";
import { devEmails }  from "../../config";

const tabs = ['invoices', 'payment transactions', 'collections history', 'notes']

export default function Lead() {
  const [tab, setTab] = useState(tabs[0]);
  const [outcomeModalOpen, setOutcomeModalOpen] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [devToolsOpen, setDevToolsOpen] = useState(false);
  const { instance } = useMsal();
  // @ts-ignore
  const { username: email } = instance.getActiveAccount();
  const [proxyEmail, setProxyEmail] = useState<string>(email);

  const {
    data: actualLead,
    isLoading: leadLoading,
    isFetching: leadFetching,
    error,
  } = useQuery({ queryKey: ["lead", proxyEmail], queryFn: () => getLead(proxyEmail) });

  const {
    data: outcomeData,
    isLoading: outcomeLoading,
    error: outcomeError,
  } = useQuery({ queryKey: ["outcomeData"], queryFn: getOutcomeOptions });

  const getOutcomesFailure = outcomeData?.status === "failure";
  const outcomesReady = !!outcomeData && !outcomeLoading && !getOutcomesFailure;

  useEffect(() => {
    console.log("actualLead", actualLead);
  }, [actualLead]);

  const actualInvoices = actualLead?.invoices;


  const onSelectInvoice = (invoice: Invoice) => {
    setSelectedInvoice(invoice);
    setInvoiceModalOpen(true);
  };

  const notes = useMemo(() => {
    return parseNotes(actualLead?.collections_information?.notes_collection);
  },[actualLead])

  const renderTabContent = () => {
    if (!actualLead) return <div>ERROR</div>;
    switch (tab) {
      case "invoices":
        return (
          <InvoicesList
            // invoices={actualInvoices as Invoice[]}
            invoices={actualInvoices as Invoice[]}
            oppnum={actualLead?.profile["ms_#"]}
            onClick={onSelectInvoice}
          />
        );
      case "payment transactions":
        return <PaymentTransactions
          transactions={actualLead?.payments ?? []} 
          />;
      case "notes":
        return <Notes notes={notes} />;
      case "loan details":
        // return <LoanDetails loan={lead.loan}/>
        return <div>Loan Details</div>;
      case "collections history":
        return (
          <CollectionsHistory
            collection_activity={actualLead.collection_activity}
            onClick={(collection: CollectionEvent) =>
              console.log("collection", collection)
            }
          />
        );
      default:
        return <div>ERROR</div>;
    }
  };

  if (leadLoading || leadFetching)
    return (
      <PageView>
        <LoadingModal open={leadLoading || leadFetching} />
      </PageView>
    );
  
  if (error) return <PageView>Error fetching lead: {error.message}</PageView>;

  if(!actualLead) return <PageView>Lead not found</PageView>;

  return (
    <PageView
      headerProps={{
        rightContent: devEmails.includes(email) && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => setDevToolsOpen(true)}
          >
            Dev Tools
          </Button>
        ),
      }}
    >
      <Container>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Tabs tabs={tabs} setTab={setTab} tab={tab} />
          <div>
            {
              getOutcomesFailure && 
              <ErrorText>
                There was a problem getting outcome options for this lead.  Please contact support.
              </ErrorText>
            }
          </div>
        </div>
        <LeadsHeader>
          <TitleContainer>
            <Title>
              <strong>Lead Details: </strong>
              {actualLead.profile.name}
            </Title>
            <Status
              status={
                actualLead.collections_information.ms_customer_status ||
                ("No Status" as InvoiceStatus)
              }
            />
            {/* <Tags tags={lead.tags}/> */}
          </TitleContainer>
          <ActionsContainer>
            <Button
              variant="outlined"
              component={Link}
              to="https://www.google.com"
            >
              Collect Payment
            </Button>
            <Tooltip title={!outcomesReady ? "There was a problem getting outcome options" : "Submit Outcome to close this Lead"}>
              <Button
                disabled={!outcomesReady}
                variant="outlined"
                onClick={() => setOutcomeModalOpen(true)}
              >
                Select Outcome
              </Button>
            </Tooltip>
          </ActionsContainer>
        </LeadsHeader>
        <LeadInfoTable lead={actualLead} />
        <Seperator />
        {renderTabContent()}
      </Container>
      {outcomesReady && (
        <OutcomeModal
          open={outcomeModalOpen}
          setOpen={setOutcomeModalOpen}
          outcomeData={outcomeData}
          internalId={actualLead.profile.customerID}
        />
      )}
      {!!selectedInvoice && (
        <InvoiceModal
          open={invoiceModalOpen}
          setOpen={setInvoiceModalOpen}
          invoice={selectedInvoice as Invoice}
        />
      )}
      <DevToolsModal
        open={devToolsOpen}
        setOpen={setDevToolsOpen}
        email={proxyEmail}
        lead={actualLead}
        methods={{
          onCopyCustomerId: () =>
            navigator.clipboard.writeText(actualLead.profile["customerID"]),
          onUnlockCurrentLead: () => console.log("unlock current lead"),
          onGetLeadProxy: (teamMember: string) =>
            console.log("get lead from team member", teamMember),
          onSelectProxyEmail: (email: string) => {
            setProxyEmail(email);
            setDevToolsOpen(false);
          },
        }}
      />
    </PageView>
  );
}

const Container = styled.div`
  padding: 2em;
  background-color: white;
  border-radius: 0.3em;
  box-shadow: 0px 0px 10px #35466933;
  padding-bottom: 6em;
`;

const LeadsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 1.3em;
  text-align: center;
  color: #354669;
  // background-color: #f0f4f5;
  text-align: left;
  padding: 0.5em;
  font-weight: normal;
  text-transform: capitalize;
`;

const Seperator = styled.div`
  width: 100%;
  height: 2px;
  background-color: #EDEDED;
  margin-top: 2em;
  margin-bottom: 1em;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

const ErrorText = styled.div`
 color: red;
`;