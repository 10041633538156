import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { Logout } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useLocation } from "react-router-dom";
// @ts-ignore
import { ReactComponent as MomentumLogo } from "../../assets/momentum-logo-2.svg";
import { COLORS } from "../../Theme";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { adminEmails } from "../../config";
import DevUrlSwitch from "./DevUrlSwitch";
import NavButton from "./nav-button/NavButton";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WidgetsIcon from '@mui/icons-material/Widgets';
import HomeIcon from '@mui/icons-material/Home';

type HeaderProps = {
  view?: string;
  children?: React.ReactNode;
  rightContent?: React.ReactNode;
};

export default function SideBar({ view, children, rightContent }:HeaderProps) {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const navigate = useNavigate();
  const { instance } = useMsal();
  // @ts-ignore
  const { name, username: email } = instance.getActiveAccount();

  return (
    <Wrapper>
      <MomentumLogo width={175} />
      <Box
        sx={{ display: "flex", alignItems: "center" }}
      >
        <PriceCheckIcon sx={{ color: COLORS.PRIMARY, fontSize: "2rem" }} />
        <Typography
          color={COLORS.PRIMARY}
          variant="h1"
          sx={{ paddingLeft: "0.3em", fontFamily: "Lato-Bold", fontSize: 14 }}
        >
          Collections Web Portal
        </Typography>
      </Box>
      <div style={{display: "flex", flexDirection: "column", gap: 7}}>
        <NavButton
            route="/"
            icon={<HomeIcon sx={{ color: "#fff" }} />}
        >
            Home
        </NavButton>
        <NavButton
            route="/lead"
            icon={<WidgetsIcon sx={{ color: "#fff" }} />}
        >
            Lead Module
        </NavButton>
        {adminEmails.includes(email) && (
            <NavButton
            route="/admin"
            icon={<AdminPanelSettingsIcon sx={{ color: "#fff" }} />}
            >
            Admin
            </NavButton>
        )}
      </div>
      <Box>{children}</Box>
    </Wrapper>
  );
}

const Wrapper = styled.header`
  background-color: rgba(0,0,0,0);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-right: 2em;
  gap: 1rem;
//   justify-content: space-between;
  width: 10vw;
//   height: 35px;
`;

const ProfileContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding-right: 2rem;
`;